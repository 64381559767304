<template>
    <div>
        <!-- DESKTOP -->
        <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-margin">
            <v-row justify="center">
                <v-col class="pl-0 pr-0 text-left" cols="12">
                    <h1 class="index-margin__title">Convenios</h1>
                </v-col>
                <v-col cols="12">
                    <v-row align="center">
                        <!-- Carrusel con una clase personalizada para controlar la altura -->
                        <v-carousel :show-arrows="true" class="custom-carousel-convenios">
                            <!-- Iterar sobre el array de convenios -->
                            <v-carousel-item v-for="(convenio, index) in convenios" :key="index" :style="{
                                backgroundImage: `url(${convenio.img_full || 'default-image.jpg'})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center'
                            }">
                                <div class="carousel-content-wrapper">
                                    <div class="content-card">
                                        <h2 class="text-shadow"><strong>{{ convenio.post_title }}</strong></h2>
                                        <!-- Usamos v-html para renderizar HTML en la descripción -->
                                        <p class="text-shadow container-description"  v-html="truncateDescription(convenio.description)"></p>
                                        <v-btn @click="verMasConvenio(convenio)" class="mt-4" color="#466BE3" dark
                                            rounded>
                                            Conoce más
                                        </v-btn>
                                    </div>
                                </div>
                            </v-carousel-item>
                        </v-carousel>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <!-- Alerta -->
        <Alert :open="message.dialog" :text="message.sms" :title="message.title" :type="message.type"
            :redirect="message.redirect" @close="message.dialog = false"></Alert>

        <!-- Cargando -->
        <v-overlay :value="loadingAll">
            <v-progress-circular :size="120" color="#466BE3" indeterminate>
                {{ $translate("general.loading") }}
            </v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";

export default {
    components: {
        Alert,
    },
    watch: {
        user(newUser) {
            if (newUser) {
                this.currentUser = newUser;
                this.getConvenios();
            }
        },
    },
    data() {
        return {
            origin: window.location.origin,
            loadingAll: false,
            infoToken: {
                document: "",
            },
            name: "",
            apps: [],
            currentUser: [],
            convenios: [],
            message: {
                dialog: false,
                title: "",
                sms: "",
                type: "",
                redirect: "",
            },
        };
    },
    methods: {
        getToken() {
            var token = localStorage.getItem("token");
            var tokenb64 = localStorage.getItem("tokenB64");
            this.token = tokenb64;
            var div = token.split(".");
            if (div.length >= 2) {
                this.infoToken = JSON.parse(atob(div[1]));
                var nameList = this.infoToken.fullName.split(" ");

                if (nameList.length > 1) {
                    if (nameList[0] != "") {
                        this.name = nameList[0];
                    } else {
                        this.name = nameList[1];
                    }
                } else {
                    this.name = "";
                }
            } else {
                this.infoToken = {
                    document: "",
                };
            }
        },

        getConvenios() {
            this.loadingAll = true;
            let cc = this.currentUser.document_number;
            let loginId = this.currentUser.profile.id;

            var data = {
                pais: this.currentUser.country,
                ciudad: this.currentUser.headquarters_city,
                sede: this.currentUser.headquarters,
            };

            Api.noAuth()
                .getConvenios(cc, loginId, data)
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.cod === 0) {
                        console.log(data,'data');
                        this.convenios = data.data.map((convenio) => {
                            return {
                                ...convenio,
                                post_date: this.formatDate(convenio.post_date),
                                img_full: convenio.img_full
                            };
                        });
                        console.log("Convenios cargados:", this.convenios);
                    } else {
                        this.message = {
                            dialog: true,
                            title: "Error",
                            sms: data.message,
                            type: "error",
                            redirect: "",
                        };
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.message = {
                        dialog: true,
                        title: "Error",
                        sms: "No se pudo obtener la lista de convenios.",
                        type: "error",
                        redirect: "",
                    };
                })
                .finally(() => {
                    this.loadingAll = false;
                });
        },

        formatDate(date) {
            const moment = require("moment");
            return moment(date).format("LL");
        },

        // En el componente que muestra la lista de convenios
        verMasConvenio(convenio) {
            // Aquí estamos pasando el `id` del convenio al detalle
            this.$router.push({ name: 'convenioDetalles', params: { id: convenio.post_id } });
        },


        truncateDescription(description) {
            if (description.length > 250) {
                let truncated = description.substring(0, 250);
                let lastSpaceIndex = truncated.lastIndexOf(' ');
                if (lastSpaceIndex !== -1) {
                    truncated = truncated.substring(0, lastSpaceIndex);
                }
                return truncated + '...';
            }
            return description;
        },
    },
    created() {
        this.getToken();

    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
    },
    mounted() {
        this.currentUser = this.$store.getters.getUser;
        if (this.currentUser) {
            this.getConvenios();
        }
    },
};
</script>



<style lang="less" scoped>
/* Estilos Santiago*/

.custom-carousel-convenios {
    height: 600px !important;
}

.custom-carousel-convenios ::v-deep .v-window-item .v-carousel__item {
    height: 550px !important;
}

.custom-carousel-convenios ::v-deep .content-card {
    bottom: 0px;
    // max-height: 250px;
}

.custom-carousel-convenios ::v-deep .v-window__prev {
    background: transparent !important;
}

.custom-carousel-convenios ::v-deep .v-window__next {
    background: transparent !important;
}


.custom-carousel-convenios ::v-deep.v-carousel__controls {
    height: 45px;
    background: transparent !important;
}

.custom-carousel-convenios ::v-deep.v-carousel__controls .v-item-group .v-btn {
    width: 15px;
    height: 15px;
}

.custom-carousel-convenios ::v-deep.v-carousel__controls .v-item-group .v-item--active::before {
    opacity: 1 !important;
}



.carousel-content-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
    /* Alinea el contenido al fondo */
    justify-content: center;
    width: 100%;
}

.content-card {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.35);
    padding: 15px;
    border-radius: 8px;
}

.text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.container-description ::v-deep span{
    font-family: RobotoRegular, Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
}
</style>